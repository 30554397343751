<template>
  <div>
    <div class="title">仓单管理</div>
    <div class="list">仓单列表</div>
    <div class="hr"></div>
    <div class="block">
      <el-form ref="form" :model="form" size="small">
        <el-row>
          <el-col :span="4">
            <el-form-item
              label="品名："
              label-width="100px"
              prop="commodityName"
            >
              <el-input
                v-model="form.commodityName"
                style="width: 160px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="品牌："
              label-width="100px"
              prop="productorName"
            >
              <el-input
                v-model="form.productorName"
                style="width: 160px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="仓库："
              label-width="100px"
              prop="warehouseName"
            >
              <el-input
                v-model="form.warehouseName"
                style="width: 160px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="零库存："
              label-width="100px"
              prop="showZeroInventory"
            >
              <el-select v-model="form.showZeroInventory" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div style="display: flex">
              <div class="btn search" @click="handleSearch">搜索</div>
              <div class="btn reset" @click="reset">重置</div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="store-sum">库存总计： {{ totalWeight }}吨</div>

    <div class="table">
      <!-- <el-table :data="tableData" :header-cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px', 'color': '#333'}" :cell-style="{'text-align':'center','font-weight': 'normal','font-size': '12px','color': '#333'}" border style="width: 100%" size="mini">
        <el-table-column v-for="(item, index) in tableHeader" :key="index" :prop="item.prop" :label="item.label" :width="item.width"></el-table-column> -->
      <div class="table">
        <table>
          <tr>
            <th v-for="(item, index) in tableHeader" :key="index">
              {{ item.label }}
            </th>
          </tr>
          <tbody v-if="tableData.length == 0">
            <tr>
              <td colspan="7" style="text-align: center">暂无数据</td>
            </tr>
          </tbody>
          <tbody v-else v-for="(item, index) in tableData" :key="index">
            <tr>
              <td style="width: 180px">{{ item.warehouseReceiptNumber }}</td>
              <td style="width: 80px">{{ item.commodityName }}</td>
              <td style="width: 80px">
                {{ item.productorName }}
              </td>
              <td style="width: 180px">
                {{ item.warehouseName }}
              </td>
              <td style="width: 180px">{{ item.location }}</td>
              <td style="width: 80px">{{ item.originalWeight }}</td>
              <td style="width: 150px">{{ item.availableWeight }}</td>
            </tr>
            <tr>
              <td colspan="7">
                可用:{{ item.availableWeight }}公斤，冻结:{{
                  item.lockWeight
                }}公斤，合计入库:{{ item.inCount }}件{{
                  item.inWeight
                }}公斤，合计出库:{{ item.outCount }}件{{ item.outWeight }}公斤
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNo"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- </el-table> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        commodityName: "",
        productorName: "",
        warehouseName: "",
        showZeroInventory: "1",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          value: "0",
          label: "不显示",
        },
        {
          value: "1",
          label: "显示",
        },
        {
          value: "2",
          label: "只显示0库存",
        },
      ],
      tableData: [],
      tableHeader: [],
      totalWeight: "",
      value: "",
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.UserInfo;
    },
  },
  created() {
    this.search();
  },
  methods: {
    handleSearch() {
      this.form.pageNo = 1;
      this.form.pageSize = 10;
      this.search();
    },

    search() {
      let params = {
        process_id: "00000209", // 流程ID
        // 传参  查询则是查询条件  可传可不传等
        nodeInfo: {
          size: this.form.pageSize, // 分页页容量（从0开始，-1代表查全部）
          page: this.form.pageNo - 1, // 分页页码（从0开始）
          warehouseReceiptNumber: "", // 仓单编号(可不传)
          commodityName: this.form.commodityName, // 品名名称
          productorName: this.form.productorName, // 品牌名称(可不传)
          warehouseName: this.form.warehouseName, // 仓库名称
          showZeroInventory: this.form.showZeroInventory, // 是否显示零库存(1-显示/其它-不显示)
        },
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then((res) => {
          this.tableHeader = res.data.title;
          this.tableData = res.data.content;
          this.totalWeight = res.data.totalWeight;
          this.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reset() {
      this.form = {
        commodityName: "",
        productorName: "",
        warehouseName: "",
        showZeroInventory: "1",
        pageNo: 1,
        pageSize: 10,
      };
      this.search();
    },
    // 改变页码
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.search();
    },
    // 改变当前页
    handleCurrentChange(val) {
      this.form.pageNo = val;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.list {
  background: #d5d5d5;
  color: #333;
  width: 110px;
  font-size: 14px;
  font-weight: bold;
  height: 28px;
  line-height: 28px;
  text-align: center;
  margin-top: 10px;
}
.hr {
  padding: 1px;
  border-bottom: 1px dashed #c8c7cc;
}
.block {
  border: #e5e5e5 1px solid;
  padding: 5px 30px;
  .btn {
    background: #2d5171;
    color: #ffffff;
    font-size: 12px;
    margin-left: 10px;
    height: 30px;
    line-height: 30px;
    width: 80px;
    margin-top: 2px;
    text-align: center;
    cursor: pointer;
  }
}
.store-sum {
  color: red;
  font-size: 14px;
  line-height: 30px;
  margin-left: 300px;
}
.table {
  margin-top: 10px;
}
table,
table tr th,
table tr td {
  border: 1px solid #dddddd;
  color: #333;
  font-size: 12px;
}
table tr th {
  text-align: center;
  line-height: 32px;
}
table {
  border-collapse: collapse;
  width: 100%;
}
table td {
  line-height: 32px;
  padding: 0 15px;
}
</style>
